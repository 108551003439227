import styled from '@emotion/styled';
import { Component } from 'react';

import LogoIconBlack from '@/components/icons/LogoIconBlack';
import { State } from '@/context/accountContext';
import { IPayment } from '@/models/IPaymentHistory.interface';
import { formatAmount } from '@/utils/formatAmount';
import formatDate from '@/utils/formatDate';

interface PaymentHistoryPrintTemplateProps {
  data?: Partial<IPayment> | null;
  account: State;
}

const PrintWrapper = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

// Class component was needed for react-to-print packege to work.
// The only working way I found to style PDF document was inline editing.

export class PaymentHistoryPrintTemplate extends Component<PaymentHistoryPrintTemplateProps> {
  render() {
    const { account, data } = this.props;
    return (
      <PrintWrapper>
        <div style={{ margin: '48px', fontFamily: 'sans-serif' }}>
          <LogoIconBlack />
          <div style={{ textAlign: 'center', fontSize: '24px' }}>Kvitto</div>
          <div style={{ margin: '24px 0' }}>
            <strong>Dragon Channel AB</strong> <br />
            Olof Palmes plats 1 <br />
            413 04 Göteborg <br />
            556956-6788 <br />
          </div>
          <div style={{ border: '1px solid #bbbbbb', margin: '24px 0' }} />
          <div style={{ margin: '24px 0' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Användare: </div> {account.auth.userId}
            </div>

            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Epost:</div> {account.auth.openId}
            </div>

            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Transaktionsdatum:</div>
              {formatDate(data?.timestamp ?? '')}
            </div>
            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Beskrivning:</div> {data?.offerV3?.title}{' '}
              {data?.offerV3?.id}
            </div>
            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Period:</div> {formatDate(data?.fromDate ?? '')} -{' '}
              {formatDate(data?.toDate ?? '')}
            </div>
          </div>
          {data?.netPrice !== undefined && (
            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Belopp:</div> {formatAmount(data?.netPrice)}{' '}
              {data?.currency}
            </div>
          )}
          {data?.netPrice !== undefined && (
            <div style={{ display: 'flex', margin: '24px 0' }}>
              <div style={{ fontWeight: 600, minWidth: '200px' }}>Varav moms (25%):</div>{' '}
              {formatAmount(data?.netPrice * 0.25)} {data?.currency}
            </div>
          )}
          {data?.netPrice !== undefined && (
            <>
              <div style={{ border: '1px solid #bbbbbb', margin: '24px 0' }} />
              <div style={{ display: 'flex', margin: '24px 0' }}>
                <div style={{ fontWeight: 600, minWidth: '200px' }}>Betalat belopp:</div>{' '}
                <strong>
                  {formatAmount(data.netPrice)} {data?.currency}
                </strong>
              </div>
            </>
          )}

          <br />
        </div>
      </PrintWrapper>
    );
  }
}
